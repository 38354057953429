/* App.css */
.App {
  text-align: center;
  width: 100vw; /* Viewport width */
  height: 100vh; /* Viewport height */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-header {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add or modify your MainContent CSS as needed */
.MainContent {
  width: 100%;
  height: 100%;
  /* Additional styling */
}
